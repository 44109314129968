import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Spinner,
  Progress,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { X } from "react-feather";
import { connect } from "react-redux";
import "swiper/css/swiper.css";
import "../../assets/scss/plugins/extensions/swiper.scss";
import "../../assets/scss/plugins/extensions/drag-and-drop.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import AddMedia from "../../assets/img/upload-files-icon.svg";
import {
  Heart,
  Calendar,
  Mail,
  Star,
  Users,
  Lock,
  ChevronDown,
  Clock,
  Check,
} from "react-feather";
import VideoDefaultThumnail from "../../assets/img/DefaultThumbnail.jpg";
import { toast } from "react-toastify";
import * as UpChunk from "@mux/upchunk";
import ProgressBar from "@ramonak/react-progress-bar";
import SplitEarning from "../../components/SplitEarning";
import TaggingInput from "../../components/TaggingInput";
import { taggingIntersection } from "../../utils/taggingIntersection";
import CheckBoxesVuexy from "../../components/@vuexy/checkbox/CheckboxesVuexy";
import TimePicker from "react-time-picker";
import Dropzone from "react-dropzone-uploader";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import TagUsersComponent from "../uiComponents/TagUsersComponent";
import { createPost, fetchReleaseForm } from "../../services/postService";
import { getAuthenticatedMuxUrl } from "../../services/muxService";
import hasFlaggedKeywords from "../../utils/hasFlaggedKeywords";
import { getUploadURL } from "../../utils/getUploadURL";
import { uploadFileToS3Promise } from "../../utils/uploadFileToS3Promise";
import {
  ShouldRefreshPostsAction,
  RemoveAllCachedPostsAction,
} from "../../redux/actions/posts/postsActions";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class NewPostModal extends React.Component {
  state = {
    value: "",
    file: [],
    imagesPreviewUrls: [],
    direction: "horizontal",
    thumbnail: null,
    price: "",
    loading: false,
    sub: "Fan",
    updated: false,
    percentage: 0,
    uploadProgress: {},
    usersForTagging: null,
    isTaggingUsersVisible: false,
    searchUsersQuery: null,
    taggedUsers: [],
    splitUsers: [],
    usersInPost: [],
    isSchedulePost: false,
    scheduleTime: new Date(),
    scheduleDay: moment().format("YYYY-MM-DD"),
    isReleaseFormModalOpen: false,
    releaseFormRichText: "",
    isAcceptButtonDisabled: true,
  };

  signal = axios.CancelToken.source();

  componentDidUpdate() {
    if (this.state.updated) {
      this.setState({
        thumbnail: this.state.imagesPreviewUrls[0]?.file,
        updated: false,
      });
    }
  }

  handleImageChange = async (files, status) => {
    //Check if file is already present
    const isAlreadySelected = this.state.imagesPreviewUrls?.find(
      (file) => file.file?.name === files.file?.name
    );
    if (isAlreadySelected) {
      toast.warn("File is already selected", {
        toastId: "file_already_selected",
      });
      return;
    }
    /////

    if (status === "done") {
      const me = this;
      const file = files.file;

      ////HEIC FILE
      if (file.type === "image/heic" || file.type === "") {
        const heic2any = await import("heic2any");
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            const preview = reader.result;

            fetch(preview)
              .then((res) => res.blob())
              .then((blob) => heic2any.default({ blob }))
              .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                const previewURL = URL.createObjectURL(conversionResult);
                const newImagesPreviewUrls = me.state.imagesPreviewUrls.concat({
                  file: conversionResult,
                  previewURL,
                });
                me.setState({
                  imagesPreviewUrls: newImagesPreviewUrls,
                  updated: true,
                });
              })
              .catch((e) => {
                // see error handling section
                console.log(e);
              });
          },
          false
        );
        reader.readAsDataURL(file);
      } else if (file.type?.startsWith("image/")) {
        let reader = new FileReader();
        reader.onloadend = () => {
          const previewURL = reader.result;

          const newImagesPreviewUrls = this.state.imagesPreviewUrls.concat({
            file,
            previewURL,
          });

          this.setState({
            imagesPreviewUrls: newImagesPreviewUrls,
            updated: true,
          });
        };
        reader.readAsDataURL(file);
      } else if (file.type === "video/mp4" || file.type === "video/quicktime") {
        if (file.size < 5368709120) {
          const newImagesPreviewUrls = this.state.imagesPreviewUrls.concat({
            file,
          });
          this.setState({
            imagesPreviewUrls: newImagesPreviewUrls,
            updated: true,
          });
        } else {
          toast.info("File is To big, file size should be less than 5gb", {
            toastId: "file_size_warning",
          });
        }
      } else {
        toast.info("File format Not Supported", {
          toastId: "file_format_warning",
        });
      }
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    // dropped outside the list
    const items = reorder(
      this.state.imagesPreviewUrls,
      result.source.index,
      result.destination.index
    );

    this.setState({
      imagesPreviewUrls: items,
      updated: true,
    });
  };

  removeItem(index) {
    this.setState({
      imagesPreviewUrls: this.state.imagesPreviewUrls.filter(
        (_, i) => i !== index
      ),
      updated: true,
    });
  }

  postHandler = async (releaseFormDetails = {}) => {
    if (this.state.isSchedulePost) {
      if (!this.state.scheduleDay) {
        toast.warn("Please enter a day to schedule the post", {
          toastId: "schedule-day",
        });
        return;
      }
      const isScheduledTimeInFuture = moment().isBefore(
        `${this.state.scheduleDay} ${this.state.scheduleTime}`
      );

      if (!isScheduledTimeInFuture) {
        toast.warn(
          "Schedule time has already passed, please select a future time",
          {
            toastId: "schedule-time",
          }
        );
        return;
      }
    }

    //////////////////////////
    //Tagging intersection between actual tags and tags in description

    const taggedUsers = taggingIntersection(
      this.state.value,
      this.state.taggedUsers
    );

    ///////////////////////
    //Locked post price validations
    if (this.state.sub === "Locked") {
      if (!this.state.price) {
        toast.warn(`Please enter a price for locked content.`, {
          toastId: "price-empty",
        });
        return;
      }

      if (+this.state.price < +this.props.user.minimum_price_content) {
        toast.warn(
          `Minimum price for locked posts should be $${+this.props.user
            .minimum_price_content}.`,
          {
            toastId: "min-price",
          }
        );
        return;
      }
    }
    //Checking for flagged keywords
    let doesContainFlaggedKeyword = false;

    doesContainFlaggedKeyword = hasFlaggedKeywords(this.state.value);

    if (doesContainFlaggedKeyword) {
      toast.warn("This post contains flagged keywords. Kindly try again!", {
        toastId: "flagged-keywords",
      });
      return;
    }
    ////////////////////////////////////////

    this.signal = axios.CancelToken.source();
    this.setState({ loading: true });

    const test = this.state.imagesPreviewUrls.filter((filter) => {
      if (filter.file) {
        return filter.file !== this.state.thumbnail;
      } else {
        return filter.conversionResult !== this.state.thumbnail;
      }
    });

    var x = test.map((val) => {
      if (val.file) {
        return val.file;
      } else {
        return val.conversionResult;
      }
    });

    let thumbnailInfo = {};
    let postMediaInfo = [];

    if (this.state.thumbnail) {
      thumbnailInfo.media_type = this.state.thumbnail.type || "image/png";

      let thumbnailPromise;
      if (this.state.thumbnail.type?.split("/")[0] === "video") {
        const muxUrlResponse = await this.createMuxUrl();

        try {
          thumbnailPromise = this.uploadToMux(
            this.state.thumbnail,
            muxUrlResponse
          );
        } catch (err) {
          toast.error(err ?? "Something went wrong while uploading video.");
        }
      } else {
        const renamedThumbnail = new File([this.state.thumbnail], uuidv4(), {
          type: this.state.thumbnail.type,
          lastModified: this.state.thumbnail.lastModified,
        });
        const uploadURLResponse = await getUploadURL();
        if (!uploadURLResponse) {
          throw new Error("cannot create upload url");
        }
        thumbnailInfo.media_path = uploadURLResponse.location;
        thumbnailPromise = uploadFileToS3Promise(
          renamedThumbnail,
          uploadURLResponse.url,
          uploadURLResponse.headers
        );
      }

      const imagePreviewPromises = x.map(async (media, idx) => {
        postMediaInfo[idx] = { media_type: media.type || "image/png" };
        if (media.type?.split("/")[0] === "video") {
          const muxUrlResponse = await this.createMuxUrl();
          try {
            return await this.uploadToMux(media, muxUrlResponse);
          } catch (err) {
            toast.error(err ?? "Something went wrong while uploading video.");
          }
        } else {
          const renamedMedia = new File([media], uuidv4(), {
            type: media.type,
            lastModified: media.lastModified,
          });

          const uploadURLResponse = await getUploadURL();
          if (!uploadURLResponse) {
            throw new Error("cannot create upload url");
          }
          postMediaInfo[idx].media_path = uploadURLResponse.location;
          return uploadFileToS3Promise(
            renamedMedia,
            uploadURLResponse.url,
            uploadURLResponse.headers
          );
        }
      });

      try {
        const promiseAllResponse = await Promise.all([
          thumbnailPromise,
          ...imagePreviewPromises,
        ]);

        if (promiseAllResponse[0].hasOwnProperty("uploadId")) {
          thumbnailInfo.media_path = promiseAllResponse[0].url;
          thumbnailInfo.upload_id = promiseAllResponse[0].uploadId;
        }

        promiseAllResponse.slice(1).forEach((response, idx) => {
          if (response.hasOwnProperty("uploadId")) {
            postMediaInfo[idx].media_path = response.url;
            postMediaInfo[idx].upload_id = response.uploadId;
          }
        });
      } catch (err) {
        toast.error("Something went wrong while uploading media.");
        console.log("MEDIA_UPLOAD_ERROR", err);
        this.setState({ loading: false, uploadProgress: {} });
        return;
      }
    }

    let formData = new FormData();
    formData.append("description", this.state.value.trim());
    this.state.thumbnail &&
      formData.append("thumbnail", JSON.stringify(thumbnailInfo));
    formData.append("post_type", this.state.sub);
    for (let i = 0; i < x.length; i++) {
      formData.append(
        "post_media[" + i + "]",
        JSON.stringify(postMediaInfo[i])
      );
    }
    for (let i = 0; i < taggedUsers.length; i++) {
      formData.append("tag_user[" + i + "]", JSON.stringify(taggedUsers[i].id));
    }
    for (let i = 0; i < this.state.usersInPost.length; i++) {
      formData.append(
        "users_in_post[" + i + "]",
        JSON.stringify(this.state.usersInPost[i].id)
      );
    }
    //For locked posts
    if (this.state.sub === "Locked") {
      formData.append("price_to_view", +this.state.price);
      const splitUsersLength = this.state.splitUsers.length;
      const { splitUsers } = this.state;
      if (splitUsersLength > 0) {
        for (let i = 0; i < splitUsersLength; i++) {
          formData.append("split_tagged_user[" + i + "]", splitUsers[i].id);
          formData.append("split_earning[" + i + "]", splitUsers[i].percent);
        }
      }
    }

    if (this.state.isSchedulePost) {
      formData.append("schedule_on", this.state.scheduleDay);
      formData.append("schedule_time", this.state.scheduleTime);
    }

    if (Object.keys(releaseFormDetails).length > 0) {
      formData.append("uploaderDetails", JSON.stringify(releaseFormDetails));
    }

    createPost(formData, { cancelToken: this.signal.token })
      .then((response) => {
        if (response.data.statusCode === 200) {
          this.setState({
            loading: false,
            value: "",
            price: "",
            thumbnail: null,
            imagesPreviewUrls: [],
            percentage: 0,
            sub: "Fan",
            uploadProgress: {},
            taggedUsers: [],
            splitUsers: [],
            usersInPost: [],
            isSchedulePost: false,
            scheduleTime: new Date(),
            scheduleDay: moment().format("YYYY-MM-DD"),
            releaseFormRichText: "",
          });
          this.props.closeModal();
          toast.success(response.data.message);

          if (
            this.props.history?.location?.pathname?.toLowerCase() === "/home"
          ) {
            this.props.ShouldRefreshPostsAction(true);
          } else {
            this.props.RemoveAllCachedPostsAction();
            this.props.history.push("/home");
          }
        } else {
          this.setState({ loading: false, uploadProgress: [] });
          toast.error("Something went wrong.");
        }
      })
      .catch((err) => {
        if (
          err.request?.status === 422 &&
          JSON.parse(err.request.response).message ===
            "The given data was invalid."
        ) {
          toast.error("Your post contains flagged keywords");
        }
        this.setState({ loading: false, uploadProgress: [] });
      });

    ///////
  };

  createMuxUrl = async () => {
    try {
      const Response = await getAuthenticatedMuxUrl();
      if (Response.data.statusCode === 200) {
        return {
          url: Response.data.data.url,
          uploadId: Response.data.data.id,
        };
      } else {
        toast.error("Something went wrong.");
      }
    } catch {
      toast.error("Something went wrong.");
    }
  };

  uploadToMux = async (file, muxUrlResponse) => {
    this.setState({
      uploadProgress: { ...this.state.uploadProgress, [file.name]: 0 },
    });

    const upload = UpChunk.createUpload({
      endpoint: muxUrlResponse.url, // Authenticated url
      file: file, // File object with your video file’s properties
      chunkSize: 30720, // Uploads the file in ~30 MB chunks
    });

    upload.on("progress", (progress) => {
      this.setState({
        uploadProgress: {
          ...this.state.uploadProgress,
          [file.name]: Math.round(progress.detail),
        },
      });
    });

    return new Promise((resolve, reject) => {
      // Subscribe to events
      upload.on("error", (error) => {
        reject(error);
      });

      upload.on("success", () => {
        resolve(muxUrlResponse);
      });
    });
  };

  addSplitUsers = (splitUsers) => {
    this.setState({ splitUsers });
  };

  addUsersInPost = (usersInPost) => {
    this.setState({ usersInPost });
  };

  setValue = (value) => {
    this.setState({ value });
  };

  setTaggedUsers = (taggedUsers) => {
    this.setState({ taggedUsers });
  };

  postClickHandler = () => {
    if (!this.state.loading) {
      if (this.state.usersInPost.length === 0) {
        this.postHandler();
      } else {
        this.getReleaseForm();
      }
    }
  };

  getReleaseForm = async () => {
    this.setState({ loading: true });
    try {
      const response = await fetchReleaseForm();
      if (response.data.statusCode === 200) {
        this.setState({
          releaseFormRichText: response.data.data,
          isReleaseFormModalOpen: true,
        });
        setTimeout(() => {
          const contentYearInputElement = document.getElementById(
            "content-upload-year"
          );

          const date = new Date();
          const currentYear = date.getFullYear();
          const currentMonth = date.getMonth();
          const currentDay = date.getDate();

          document.querySelector(
            `#content-upload-day :nth-child(${currentDay})`
          ).selected = true;
          document.querySelector(
            `#content-upload-month :nth-child(${currentMonth + 1})`
          ).selected = true;

          for (let i = currentYear - 10; i <= currentYear; i++) {
            let yearElem = document.createElement("option");
            yearElem.value = i;
            yearElem.textContent = i;
            if (i === currentYear) {
              yearElem.selected = true;
            }
            contentYearInputElement.append(yearElem);
          }
        }, 0);
      } else {
        toast.error("Error fetching release form, please try again later.");
      }
    } catch {
      toast.error("Error fetching release form, please try again later.");
    }
    this.setState({ loading: false });
  };

  closeReleaseModal = () => {
    this.setState({
      isReleaseFormModalOpen: false,
      isAcceptButtonDisabled: true,
    });
  };

  releaseFormAcceptHandler = () => {
    const uploaderSignInputElement =
      document.getElementById("uploader-signature");
    const uploaderSign = uploaderSignInputElement.value.trim();

    const contentInputElement = document.getElementById("content-production");
    const contentTitle = contentInputElement.value.trim();

    const contentDayInputElement =
      document.getElementById("content-upload-day");
    const contentDay = contentDayInputElement.value;

    const contentMonthInputElement = document.getElementById(
      "content-upload-month"
    );
    const contentMonth = contentMonthInputElement.value;

    const contentYearInputElement = document.getElementById(
      "content-upload-year"
    );
    const contentYear = contentYearInputElement.value;

    const uploaderStateInputElement =
      document.getElementById("uploader-residence");
    const uploaderState = uploaderStateInputElement.value.trim();

    const MONTHS = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daysInMonth = new Date(
      contentYear,
      MONTHS.indexOf(contentMonth) + 1,
      0
    ).getDate();

    if (!contentTitle) {
      toast.warn("Please enter content title", {
        toastId: "release-form-content-title",
      });
      contentInputElement.focus();
      contentInputElement.value = "";
    } else if (!contentDay) {
      toast.warn("Please enter content upload day", {
        toastId: "release-form-content-day",
      });
      contentDayInputElement.focus();
    } else if (!contentMonth) {
      toast.warn("Please enter content upload month", {
        toastId: "release-form-content-month",
      });
      contentMonthInputElement.focus();
    } else if (!contentYear) {
      toast.warn("Please enter content upload year", {
        toastId: "release-form-content-year",
      });
      contentYearInputElement.focus();
    } else if (contentDay > daysInMonth) {
      toast.warn(`${contentMonth} only has ${daysInMonth} days`, {
        toastId: "release-form-incorrect-day",
      });
    } else if (!uploaderState) {
      toast.warn("Please enter your state of residence", {
        toastId: "release-form-uploader-state",
      });
      uploaderStateInputElement.focus();
      uploaderStateInputElement.value = "";
    } else if (!uploaderSign) {
      toast.warn("Please sign the release form", {
        toastId: "release-form-uploader-sign",
      });
      uploaderSignInputElement.focus();
      uploaderSignInputElement.value = "";
    } else {
      this.closeReleaseModal();
      this.postHandler({
        content_title: contentTitle,
        uploader_state: uploaderState,
        content_day: contentDay,
        content_month: contentMonth,
        content_year: contentYear,
        uploader_signature: uploaderSign,
      });
    }
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isModalOpen}
          backdrop={true}
          className="modal-dialog-centered upload-post-modal-wrapper"
          style={this.state.isReleaseFormModalOpen ? { display: "none" } : null}
        >
          <ModalHeader
            toggle={() => {
              this.signal.cancel();
              this.setState({
                sub: "Fan",
                value: "",
                file: [],
                imagesPreviewUrls: [],
                thumbnail: "",
                price: "",
                percentage: 0,
                taggedUsers: [],
                splitUsers: [],
                usersInPost: [],
                isSchedulePost: false,
                scheduleTime: new Date(),
                scheduleDay: moment().format("YYYY-MM-DD"),
                loading: false,
                uploadProgress: {},
              });
              this.props.closeModal();
            }}
          >
            NEW POST !!!
          </ModalHeader>
          <ModalBody className="modal-dialog-centered">
            <div className="upload-post-modal-content-left">
              <div className="holder">
                <div className="fans-selection">
                  <div className="fans-selection-dropdown-outer">
                    <div style={{ width: "100%" }}>
                      <FormGroup className="mb-0">
                        <div className="input-group">
                          <Input
                            type="select"
                            name="select"
                            onChange={(e) =>
                              this.setState({ sub: e.target.value })
                            }
                            disabled={this.state.loading}
                          >
                            <option value="Fan">Fans</option>
                            <option
                              value="Subscription"
                              icon={<Heart className="vx-icon" size={12} />}
                            >
                              Subscription
                            </option>
                            <option value="Locked">Locked</option>
                          </Input>
                          <span className="dropdown-icon">
                            <ChevronDown />
                          </span>
                        </div>
                        <span className="selection-notes d-flex font-small-2">
                          {this.state.sub === "Fan" ? (
                            <Star />
                          ) : this.state.sub === "Subscription" ? (
                            <Users />
                          ) : this.state.sub === "Locked" ? (
                            <Lock />
                          ) : null}
                          {this.state.sub === "Fan" ? (
                            <span>Content is Visible to all your Fans</span>
                          ) : this.state.sub === "Subscription" ? (
                            <span>
                              Content is Visible to all your Subscribers
                            </span>
                          ) : this.state.sub === "Locked" ? (
                            <span>
                              Content will not be visible to anyone unless they
                              buy this post individually
                            </span>
                          ) : null}
                        </span>
                      </FormGroup>
                      {this.state.sub === "Locked" ? (
                        <FormGroup className="form-label-group position-relative has-icon-left mb-0">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>$</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              style={{ paddingLeft: "10px" }}
                              type="number"
                              min="0"
                              placeholder="Price"
                              onChange={(e) =>
                                this.setState({ price: e.target.value })
                              }
                              disabled={this.state.loading}
                            />
                          </InputGroup>
                        </FormGroup>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="message-box">
                  <p>
                    Add Your Message <small>*</small>
                  </p>
                  <div className="message-box-input-field">
                    <TaggingInput
                      setValueInParent={this.setValue}
                      setTaggedUsersInParent={this.setTaggedUsers}
                      isPosting={this.state.loading}
                    />
                    <small
                      className={`counter-value float-right ${
                        this.state.value?.length > 250 ? "bg-danger" : ""
                      }`}
                    >{`${this.state.value?.length}/250`}</small>
                    <div className="message-box-notes">
                      <small>
                        <span>*</span>Your post will be under review and will
                        only be visible if approved.
                      </small>
                    </div>
                  </div>
                </div>
                <div className="post-now-action-area desktop-version">
                  <CheckBoxesVuexy
                    name="check"
                    checked={this.state.isSchedulePost}
                    color="primary"
                    icon={<Check className="vx-icon" size={16} />}
                    value="test"
                    label="Schedule Post"
                    onChange={() =>
                      this.setState((prevState) => ({
                        isSchedulePost: !prevState.isSchedulePost,
                      }))
                    }
                    disabled={this.state.loading}
                  />
                  {this.state.isSchedulePost && (
                    <div className="schedule-post-dropdown-outer d-flex">
                      <div className="schedule-post-dropdown-item">
                        <div className="input-field">
                          <label>Select a day</label>
                          <Flatpickr
                            className="form-control"
                            name="dob"
                            id="dob"
                            options={{ minDate: moment().format("YYYY-MM-DD") }}
                            onChange={(e) =>
                              this.setState({
                                scheduleDay: moment(e[0]).format("YYYY-MM-DD"),
                              })
                            }
                            value={this.state.scheduleDay}
                            disabled={this.state.loading}
                          />
                          <div className="sign-up-form-field-icon">
                            <Calendar />
                          </div>
                          <span
                            className="dropdown-icon"
                            style={{ pointerEvents: "none" }}
                          >
                            <ChevronDown />
                          </span>
                        </div>
                      </div>
                      <div className="schedule-post-dropdown-item">
                        <div className="input-field">
                          <label>Time</label>

                          <TimePicker
                            onChange={(value) =>
                              this.setState({ scheduleTime: value })
                            }
                            value={this.state.scheduleTime}
                            style={{ color: "#fff" }}
                            disableClock={true}
                            format="h:mm a"
                            clearIcon={null}
                            disabled={this.state.loading}
                          />
                          <div className="sign-up-form-field-icon">
                            <Clock />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="submit-btn">
                    {this.state.percentage !== 0 ? (
                      <Progress
                        className="progress-lg"
                        value={this.state.percentage}
                        color="primary"
                        style={{ width: "75%", height: "20px" }}
                      >
                        {this.state.percentage}%
                      </Progress>
                    ) : null}
                    <Button.Ripple
                      color="primary"
                      onClick={this.postClickHandler}
                      disabled={
                        this.state.value.trim() === "" ||
                        (this.state.sub === "Locked" &&
                          this.state.price === "") ||
                        (this.state.sub === "Locked" &&
                          this.state.imagesPreviewUrls.length === 0)
                      }
                    >
                      {!this.state.loading ? (
                        "Post"
                      ) : (
                        <Spinner style={{ color: "#fff" }} size="md" />
                      )}
                    </Button.Ripple>
                  </div>
                </div>

                <div className="post-upload-type-outer d-none">
                  <div className="schedule-post">
                    <p>
                      Schedule Post <Calendar />
                    </p>
                    <div className="schedule-post-popup d-none">
                      <div className="holder">
                        <div className="schedule-post-popup-dropdown-outer">
                          <div className="schedule-post-popup-dropdown-item">
                            <FormGroup>
                              <label>Select a day</label>
                              <Flatpickr
                                className="form-control"
                                name="dob"
                                id="dob"
                              />
                              <div className="sign-up-form-field-icon">
                                <Calendar />
                              </div>
                              <span className="dropdown-icon">
                                <ChevronDown />
                              </span>
                            </FormGroup>
                          </div>
                          <div className="schedule-post-popup-dropdown-item">
                            <div className="time-picker">
                              <div className="clock-icon">
                                <Clock />
                              </div>
                              <label>Start time</label>
                              <UncontrolledDropdown>
                                <DropdownToggle className="EditMore">
                                  <ChevronDown size={16} />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem className="editPost">
                                    10:30 AM
                                  </DropdownItem>
                                  <DropdownItem className="editPost">
                                    11:00 AM
                                  </DropdownItem>
                                  <DropdownItem className="editPost">
                                    11:30 AM
                                  </DropdownItem>
                                  <DropdownItem className="editPost">
                                    12:00 PM
                                  </DropdownItem>
                                  <DropdownItem className="editPost">
                                    12:30 PM
                                  </DropdownItem>
                                  <DropdownItem className="editPost">
                                    13:00 PM
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="label">
                    <p>or</p>
                  </div>
                  <div className="post-now">
                    <p>
                      Post Now <Mail />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="upload-post-modal-content-right">
              <div className="holder">
                <TagUsersComponent
                  setTaggedUsers={this.addUsersInPost}
                  disableInput={this.state.loading}
                  header={"Tag co-performers in your video."}
                  footer="*You and the co-performers will have to sign a release form"
                />
                {this.state.sub === "Locked" ? (
                  <SplitEarning
                    addSplitUsers={this.addSplitUsers}
                    price={this.state.price}
                    isPosting={this.state.loading}
                  />
                ) : null}
                <div className="add-media-outer">
                  <p>Add Media</p>

                  <div className="add-media-dropzone">
                    <label className="text-center">
                      <div className="new-post-featured-image-dropzone-outer">
                        <Dropzone
                          onChangeStatus={this.handleImageChange}
                          accept="image/*, video/*, image/heic"
                          disabled={this.state.loading}
                        />
                      </div>
                      <img src={AddMedia} alt="/" />
                      Drop your media here or browse
                      <br />
                      Images and videos upto 5GB are allowed
                    </label>
                  </div>
                  <PerfectScrollbar
                    className="upload-media-preview-scrollbar"
                    options={{
                      wheelPropagation: true,
                    }}
                  >
                    <div
                      className="upload-media-preview-outer"
                      style={{ width: "100%" }}
                    >
                      <Row>
                        <Col sm="12" style={{ overflow: "auto" }}>
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable
                              droppableId="droppable"
                              direction={this.state.direction}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards"
                                >
                                  {/* <Badge
                              pill
                              color="primary"
                              style={{
                                position: "absolute",
                                bottom: "30px",
                                left: "40px",
                                color: "#fff",
                                zIndex: "1",
                              }}
                            >
                              Thumbnail
                            </Badge> */}
                                  {this.state.imagesPreviewUrls.map(
                                    (item, index) => {
                                      return (
                                        <Col
                                          sm="4"
                                          key={index}
                                          style={
                                            item.file?.type === "video/mp4"
                                              ? { height: 150 + "px" }
                                              : {}
                                          }
                                        >
                                          <Draggable
                                            key={index}
                                            draggableId={`${index}`}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                {!this.state.loading && (
                                                  <X
                                                    style={{
                                                      position: "absolute",
                                                      top: "10px",
                                                      right: "10px",
                                                      stroke: "#fff",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      this.removeItem(index);
                                                    }}
                                                  />
                                                )}
                                                {!item.file?.type?.includes(
                                                  "video"
                                                ) ? (
                                                  <img
                                                    src={item.previewURL}
                                                    style={{ width: "100%" }}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <img
                                                    src={VideoDefaultThumnail}
                                                    style={{
                                                      width: "100%",
                                                      height: "150px",
                                                    }}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </Draggable>
                                        </Col>
                                      );
                                    }
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Col>
                      </Row>
                    </div>
                  </PerfectScrollbar>
                  {this.state.loading && (
                    <div className="post-upload-progressbar-wrapper">
                      <div className="post-upload-notice">
                        <small>
                          Upload in progress. Please stay on this page until
                          it's completed.
                        </small>
                      </div>

                      <div
                        className="post-media-upload-status order-2"
                        style={{ width: "100%" }}
                      >
                        {Object.keys(this.state.uploadProgress).length > 0 && (
                          <ul style={{ width: "100%", listStyle: "none" }}>
                            {Object.keys(this.state.uploadProgress).map(
                              (key) => (
                                <li key={key} style={{ width: "100%" }}>
                                  <div className="post-media-upload-inner mb-1">
                                    <p className="mb-0">
                                      {" "}
                                      {key.split(".")[0].length > 50
                                        ? `${key
                                            .split(".")[0]
                                            .slice(0, 50)}... :`
                                        : `${key.split(".")[0]} :`}
                                    </p>
                                    <div className="post-media-upload-holder">
                                      <ProgressBar
                                        completed={
                                          this.state.uploadProgress[key]
                                        }
                                        maxCompleted={100}
                                        transitionDuration="0.1s"
                                        width="100%"
                                      />
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="post-now-action-area mobile-version d-none">
                <CheckBoxesVuexy
                  name="check"
                  checked={this.state.isSchedulePost}
                  color="primary"
                  icon={<Check className="vx-icon" size={16} />}
                  value="test"
                  label="Schedule Post"
                  onChange={() =>
                    this.setState((prevState) => ({
                      isSchedulePost: !prevState.isSchedulePost,
                    }))
                  }
                  disabled={this.state.loading}
                />
                {this.state.isSchedulePost && (
                  <div className="schedule-post-dropdown-outer d-flex">
                    <div className="schedule-post-dropdown-item">
                      <div className="input-field">
                        <label>Select a day</label>
                        <Flatpickr
                          className="form-control"
                          name="dob"
                          id="dob"
                          options={{ minDate: moment().format("YYYY-MM-DD") }}
                          onChange={(e) =>
                            this.setState({
                              scheduleDay: moment(e[0]).format("YYYY-MM-DD"),
                            })
                          }
                          value={this.state.scheduleDay}
                          disabled={this.state.loading}
                        />
                        <div className="sign-up-form-field-icon">
                          <Calendar />
                        </div>
                        <span
                          className="dropdown-icon"
                          style={{ pointerEvents: "none" }}
                        >
                          <ChevronDown />
                        </span>
                      </div>
                    </div>
                    <div className="schedule-post-dropdown-item">
                      <div className="input-field">
                        <label>Time</label>
                        <TimePicker
                          onChange={(value) =>
                            this.setState({ scheduleTime: value })
                          }
                          value={this.state.scheduleTime}
                          style={{ color: "#fff" }}
                          disableClock={true}
                          format="h:mm a"
                          clearIcon={null}
                          disabled={this.state.loading}
                        />
                        <div className="sign-up-form-field-icon">
                          <Clock />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="submit-btn">
                  {this.state.percentage !== 0 ? (
                    <Progress
                      className="progress-lg"
                      value={this.state.percentage}
                      color="primary"
                      style={{ width: "75%", height: "20px" }}
                    >
                      {this.state.percentage}%
                    </Progress>
                  ) : null}
                  <Button.Ripple
                    color="primary"
                    onClick={this.postClickHandler}
                    disabled={
                      this.state.value.trim() === "" ||
                      (this.state.sub === "Locked" &&
                        this.state.price === "") ||
                      (this.state.sub === "Locked" &&
                        this.state.imagesPreviewUrls.length === 0)
                    }
                  >
                    {!this.state.loading ? (
                      "Post"
                    ) : (
                      <Spinner style={{ color: "#fff" }} size="md" />
                    )}
                  </Button.Ripple>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isReleaseFormModalOpen}
          toggle={this.closeReleaseModal}
          className="modal-dialog-centered upload-post-modal-wrapper release-form-modal-wrapper"
        >
          <ModalHeader toggle={this.closeReleaseModal}>
            Model release Form
          </ModalHeader>
          <ModalBody
            className="modal-dialog-centered"
            onScroll={(e) => {
              if (
                e.target.offsetHeight + e.target.scrollTop >=
                e.target.scrollHeight - 10
              ) {
                this.setState({ isAcceptButtonDisabled: false });
              }
            }}
          >
            <PerfectScrollbar
              className="user-chats"
              options={{
                wheelPropagation: true,
              }}
            >
              <div className="container specific">
                <div
                  className="release-form-content-holder"
                  dangerouslySetInnerHTML={{
                    __html: this.state.releaseFormRichText,
                  }}
                ></div>
              </div>
            </PerfectScrollbar>
          </ModalBody>
          <ModalFooter>
            <Button
              className="orange-btn"
              color="primary"
              size="md"
              onClick={this.releaseFormAcceptHandler}
              disabled={this.state.isAcceptButtonDisabled}
            >
              {this.state.loading ? (
                <Spinner style={{ color: "#d3af37" }} />
              ) : (
                "Sign"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    flaggedKeywords: state.app.flaggedKeywords,
  };
};

const mapDispatchToProps = {
  ShouldRefreshPostsAction,
  RemoveAllCachedPostsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPostModal);
